export const cart = () => {

    const quantityBlock = document.querySelectorAll('.js-quantity');
    const cartCheckAll = document.querySelector('.js-cart-check-all');
    const cartList = document.querySelector('.js-cart-list');

    if ( quantityBlock.length ) {
        for (let block of quantityBlock ) {
            
            let minus = block.querySelector(".quantity__button_minus");
            let plus = block.querySelector(".quantity__button_plus");
            let input = block.querySelector("input");

            minus.addEventListener('click',function(){
                input.value = +input.value - 1;
                if ( +input.value < +input.min ) {
                    input.value = +input.min;
                }
            });

            plus.addEventListener('click',function(){
                input.value = +input.value + 1;
                if ( +input.value > +input.max ) {
                    input.value = +input.max;
                }
            });

            input.onchange = () => {
                if ( +input.value > +input.max ) {
                    input.value = +input.max;
                }
                if ( +input.value < +input.min ) {
                    input.value = +input.min;
                }
            }

            input.oninput = () => {
                input.value = +input.value.replace("-", "");
            }

        }
    }


    if ( cartCheckAll && cartList ) {

        let productsLabels = cartList.querySelectorAll(".cart__card-check");
        let productsChecks = cartList.querySelectorAll(".cart__card-check input");

        for (let label of productsLabels ) {
            label.addEventListener('click',function(){
                if ( !label.querySelector("input").checked ) {
                    cartCheckAll.checked = false;
                }

                let mark = 1;
                
                for (let inp of productsChecks ) {
                    if ( !inp.checked ) {
                        mark = 0;
                    }
                }

                if ( mark == 1 ) {
                    cartCheckAll.checked = true;
                }

            });
        }

        cartCheckAll.addEventListener('change',function(){
            
            if ( cartCheckAll.checked ) {
                
                for (let check of productsChecks ) {
                    check.checked = true;
                }

            } else {
                
                for (let check of productsChecks ) {
                    check.checked = false;
                }

            }

        });
    }

}