export default function changeInput() {
  const inputs = document.querySelectorAll(".input-block");

  inputs.forEach((input) => {
    const change = input.querySelector(".input-title-change");
    const field = input.querySelector("input");

    change.addEventListener("click", () => {
      if (field.disabled) {
        change.textContent = change.dataset.inputSave;

        field.disabled = false;

        if ((field.type = "password")) {
          field.type = "text";
          field.setAttribute("data-input-password", true);
        }

        field.focus();
      } else {
        change.textContent = "изменить";
        field.disabled = true;

        if (field.getAttribute("data-input-password")) {
          field.type = "password";
        }
      }
    });
  });
}
