export const accordion = () => {

    const accordions = document.querySelectorAll('.js-accordion');

    if ( accordions.length ) {
        
        for (let card of accordions ) {

            card.addEventListener('click',function(){

                card.classList.toggle("accordion__top_open");

                let hight = card.parentElement.querySelector(".accordion__content").offsetHeight;
                let hide = card.parentElement.querySelector(".accordion__hide");

                if ( card.classList.contains("accordion__top_open") ) {
                    hide.style.height = hight + "px";
                } else {
                    hide.style.height = 0 + "px";
                }
                
            });

        }
    }


    document.querySelector('.footer')?.querySelectorAll('.accordion__top')[1]?.click();;


}