import Swiper from 'swiper/bundle';

export const swipers = () => {

  const mainFirstSwiper = new Swiper('.js-main-first-swiper', {
    slidesPerView: 1,
    autoplay: {
        delay: 10000,
        disableOnInteraction: true
    },
    navigation: {
        prevEl: ".main-first__swiper-prev",
        nextEl: ".main-first__swiper-next",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    
  });

  const mainCatalogSwiper = new Swiper('.js-main-catalog-swiper', {
    enabled: true,
    slidesPerView: 'auto',
    //spaceBetween: 100,
    breakpoints: {
      480: {
        enabled: false,
        slidesPerView: 'auto',
      },
    },

  });

  const mainNewsSwiper = new Swiper('.js-main-news-swiper', {
    slidesPerView: 'auto',
    //spaceBetween: 100,
    autoplay: {
        delay: 10000,
        disableOnInteraction: true
    },
    navigation: {
        prevEl: ".main-news__swiper-prev",
        nextEl: ".main-news__swiper-next",
    },
    loop: true,
    //centeredSlides: true,
    //centeredSlidesBounds: true
    
  });


  const allCatalogSwipers = document.querySelectorAll('.js-catalog-swiper');

  if ( allCatalogSwipers.length ) {
    
    for (let el of allCatalogSwipers ) {
      let addiction = el.dataset.addiction;
      const catalogSwiper = new Swiper('.js-catalog-swiper[data-addiction="'+addiction+'"]', {
        slidesPerView: 'auto',
        //spaceBetween: 100,
        autoplay: {
            delay: 10000,
            disableOnInteraction: true
        },
        navigation: {
            prevEl: '.block-catalog__swiper-prev[data-addiction="'+addiction+'"]',
            nextEl: '.block-catalog__swiper-next[data-addiction="'+addiction+'"]',
        },
        //loop: true,
        //centeredSlides: false,
        //centeredSlidesBounds: true
        
      });
    }

    

  }

  



  

  const categoriesSwiper = new Swiper('.js-categories-swiper', {
    enabled: true,
    slidesPerView: 'auto',
    //spaceBetween: 100,
    breakpoints: {
      768: {
        enabled: false,
        slidesPerView: 'auto',
      },
    },
  });

  const pageTopSwiper = new Swiper('.js-page-top-swiper', {
    enabled: true,
    slidesPerView: 'auto',
    breakpoints: {
      481: {
        enabled: false,
        slidesPerView: 'auto',
      },
    },
  });

  const productColorSwiper = new Swiper('.js-product-color-swiper', {
    slidesPerView: 'auto',
    navigation: {
      prevEl: ".block-product__swiper-prev",
      nextEl: ".block-product__swiper-next",
    },
  });

  const productSizeSwiper = new Swiper('.js-product-size-swiper', {
    slidesPerView: 'auto',
  });


  const productSwiper = new Swiper('.js-product-swiper', {
    enabled: true,
    slidesPerView: 'auto',
    breakpoints: {
      481: {
        enabled: false,
        slidesPerView: 'auto',
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });


  



  

  const modalAdditionSwiper = new Swiper('.js-modal-addition-swiper', {
    slidesPerView: "auto",
    //spaceBetween: 100,
    autoplay: {
        delay: 10000,
        disableOnInteraction: true
    },
    navigation: {
        prevEl: ".modal__addition-prev",
        nextEl: ".modal__addition-next",
    },
    //loop: true,
    //centeredSlides: true,
    //centeredSlidesBounds: true
    
  });



}