
export const login = () => {
   

    const loginType = document.querySelector('.js-modal-login-type');
    const loginForms = document.querySelectorAll('.js-login-form');
    const restoreEmailInput = document.querySelector('.js-email-restore-input');
    const restoreEmailLink = document.querySelectorAll('.js-email-restore-link');


    if ( restoreEmailLink.length ) {
        for (let link of restoreEmailLink ) {
            link.addEventListener('click',function(e){
                e.preventDefault();
                let thisForm = link.closest("form")
                thisForm.classList.add("display-none");
                document.querySelector('.modal__login-form[data-login-form="restorePassword"]')?.classList.remove("display-none");
            });
        }
    }


    if ( loginType ) {
        loginType.addEventListener('change',function(){

            if ( loginType.checked ) {
                //alert('reg');

                document.querySelector('.js-login-wrap[data-login-type="signup"]')?.classList.remove("display-none");
                document.querySelector('.js-login-wrap[data-login-type="login"]')?.classList.add("display-none");

            } else {
                //alert('auth');

                document.querySelector('.js-login-wrap[data-login-type="signup"]')?.classList.add("display-none");
                document.querySelector('.js-login-wrap[data-login-type="login"]')?.classList.remove("display-none");

            }

        });
    }


    function modal_close() {
        document.dispatchEvent(
            new KeyboardEvent("keydown", {
              keyCode: 27,
            })
        );
    }


    if ( loginForms.length ) {
        for ( let form of loginForms) {
        form.addEventListener('submit', e => {
            e.preventDefault();

            let formData = new FormData(form);
            let formAction = form.getAttribute("action");
            let formThis = form.dataset.loginForm;
            let formSuccess = form.dataset.success;

            fetch(formAction, {
                method: "POST",
                body: formData
            }).then(response => response.json()).then(response => {
                if (response.success) {
                    form.reset();


                    if ( formSuccess ) {
                        document.querySelector(`.modal__login-form[data-login-form=${formThis}]`)?.classList.add("display-none");
                        document.querySelector(`.modal__login-form[data-login-form=${formSuccess}]`)?.classList.remove("display-none");
                    }


                    if ( formThis == 'restorePassword') {
                        form.querySelector(".input-block")?.classList.remove("error-field");
                        form.querySelector(".input-block")?.classList.remove("error-field-response");
                    }

                    if ( formThis == 'signup-sms' ) {
                        form.querySelector(".input-block")?.classList.remove("error-field");
                        form.querySelector(".input-block")?.classList.remove("error-field-response");
                        
                        modal_close();
                        //document.querySelector('.modal_open')?.classList.remove("modal_open");
                        document.querySelector('.modal[data-modal="hello"]')?.classList.add("modal_open");
                        setTimeout(() => {
                            modal_close();
                        }, 1500);
                    }

                    if ( formThis == 'signup' ) {
                        if ( document.querySelector('.js-login-phone-place') ) {
                            form.closest(".modal__login-tab").querySelector('.js-login-phone-place').innerHTML = response.tel;
                        }
                        alert(
                            'Ваш код ' + response.code
                        );
                    }

                    if ( formThis == 'loginByPhone' ) {
                        if ( document.querySelector('.js-login-phone-place') ) {
                            form.closest(".modal__login-tab").querySelector('.js-login-phone-place').innerHTML = response.tel;
                        }
                        alert(
                            'Ваш код ' + response.code
                        );
                    }

                    if ( formThis == 'loginByPhoneSms' ) {
                        form.querySelector(".input-block")?.classList.remove("error-field");
                        form.querySelector(".input-block")?.classList.remove("error-field-response");
                        
                        modal_close();
                        //document.querySelector('.modal_open')?.classList.remove("modal_open");
                        document.querySelector('.modal[data-modal="hello"]')?.classList.add("modal_open");
                        setTimeout(() => {
                            modal_close();
                        }, 1500);
                    }

                    if ( formThis == 'loginByTelegram' ) {
                        alert('Редирект в бота');
                    }

                    if ( formThis == 'loginByEmail' ) {
                        modal_close();
                        //document.querySelector('.modal_open')?.classList.remove("modal_open");
                        document.querySelector('.modal[data-modal="hello"]')?.classList.add("modal_open");
                        setTimeout(() => {
                            modal_close();
                        }, 1500);
                    }


                    if ( formThis == 'loginByEmail' ) {
                        form.querySelector('input[type="password"]')?.closest(".input-block")?.classList.remove("error-field");
                        form.querySelector('input[type="password"]')?.closest(".input-block")?.classList.remove("error-field-response");
                    }


                } else {
                    
                    //alert('form send error');


                    if ( formThis == 'restorePassword' ||
                         formThis == 'signup-sms' ||
                         formThis == 'loginByPhoneSms'
                     ) {
                        form.querySelector(".input-block")?.classList.add("error-field");
                        form.querySelector(".input-block")?.classList.add("error-field-response");
                    }

                    if ( formThis == 'loginByEmail' ) {
                        form.querySelector('input[type="password"]')?.closest(".input-block")?.classList.add("error-field");
                        form.querySelector('input[type="password"]')?.closest(".input-block")?.classList.add("error-field-response");
                    }



                    if ( 
                        formThis == 'loginByTelegram'
                    ) {
                        document.querySelector(`.modal__login-form[data-login-form=${formThis}]`)?.classList.add("display-none");
                        document.querySelector(`.modal__login-form[data-login-form="loginByTelegramError"]`)?.classList.remove("display-none");
                    }



                }
            });

        })
        }
    }






    if ( restoreEmailInput ) {
        restoreEmailInput.addEventListener('input',function(){
            
            if ( restoreEmailInput.checkValidity() ) {
                restoreEmailInput.closest("form")?.querySelector("button")?.removeAttribute("disabled");
            } else {
                restoreEmailInput.closest("form")?.querySelector("button")?.setAttribute("disabled", "true");
            }

        });
    }

}