export const autoRadio = () => {

    const autoRadioColors = document.querySelectorAll('.js-auto-radio-colors');
    const autoRadioSizes = document.querySelectorAll('.js-auto-radio-sizes');
    const autoLabels = document.querySelectorAll('.js-auto-label');
    var startColor = 0;
    var startSize = 0;
    var startLabel = 0;

    if ( autoRadioColors.length ) {
        for (let block of autoRadioColors ) {
            let labels = block.querySelectorAll('label');
            for (let i = 0; i < labels.length; i++) {
                if ( labels[i].getAttribute("for") == '' ) {
                    labels[i].setAttribute("for", `auto-radio-color-${startColor}-${i}`);
                    labels[i].querySelector("input").setAttribute("name", `auto-radio-color-${startColor}`);
                    labels[i].querySelector("input").setAttribute("id", `auto-radio-color-${startColor}-${i}`);
                }
            }
            startColor = startColor + 1;
        }
    }

    if ( autoRadioSizes.length ) {
        for (let block of autoRadioSizes ) {
            let labels = block.querySelectorAll('label');
            for (let i = 0; i < labels.length; i++) {
                labels[i].setAttribute("for", `auto-radio-size-${startSize}-${i}`);
                labels[i].querySelector("input").setAttribute("name", `auto-radio-size-${startSize}`);
                labels[i].querySelector("input").setAttribute("id", `auto-radio-size-${startSize}-${i}`);
            }
            startSize = startSize + 1;
        }
    }

    if ( autoLabels.length ) {
        for (let block of autoLabels ) {
            let labels = block.querySelectorAll('label');
            for (let i = 0; i < labels.length; i++) {
                if ( labels[i].getAttribute("for") == '' ) {
                    labels[i].setAttribute("for", `auto-label-${startLabel}-${i}`);
                    labels[i].querySelector("input").setAttribute("name", `auto-label-${startLabel}`);
                    labels[i].querySelector("input").setAttribute("id", `auto-label-${startLabel}-${i}`);
                }
            }
            startLabel = startLabel + 1;
        }
    }




}