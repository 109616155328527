export const modals = () => {

    const modalOpen = 'js-modal-open';
    const modalClose = document.querySelectorAll('.js-modal-close');
    const modals = document.querySelectorAll('.modal');
    var distance = 0;

    function disable_scroll() {
        document.querySelector(".site-container").classList.add("dis-scroll");
        document.querySelector(".site-container").scrollTo({
          top: distance,
          left: 0,
        });
    }

    function enable_scroll() {
        document.querySelector(".site-container").classList.remove("dis-scroll");
        setTimeout(() => {
            window.scrollTo({
                top: distance,
                left: 0,
            });
        }, 5);
    }


    document.addEventListener('click',function(e){
        if ( e.target.classList.contains(modalOpen) ) {

            let modalName = e.target.dataset.modal;

            if (
                modalName == 'sizes' ||
                modalName == 'filters'
            ) {
                if ( window.innerWidth <= 744 ) {
                    distance = window.pageYOffset;
                    disable_scroll();
                    document.querySelector(`.modal[data-modal=${modalName}]`)?.classList.add("modal_open");
                }
            } else {
                distance = window.pageYOffset;
                disable_scroll();
                document.querySelector(`.modal[data-modal=${modalName}]`)?.classList.add("modal_open");
            }
            
            
            
        }
    });









    document.addEventListener('keydown', function(e) {
        let keyCode = e.keyCode;
        if (keyCode === 27) {
            document.querySelector('.modal_open')?.classList.remove('modal_open');
            enable_scroll();
        }
    });

    if ( modals.length ) {
        for (let modal of modals ) {
            modal.addEventListener('mousedown',function(e){
                if ( e.target.classList.contains("modal") ) {
                    document.querySelector('.modal_open')?.classList.remove('modal_open');
                    enable_scroll();
                }
            });
        }
    }

    if ( modals.length ) {
        for (let close of modalClose ) {
            close.addEventListener('mousedown',function(e){
                document.querySelector('.modal_open')?.classList.remove('modal_open');
                enable_scroll();
            });
        }
    }


}