export const profile = () => {

    const profileBanner = document.querySelector('.js-profile-banner');


    if ( profileBanner ) {
        profileBanner.addEventListener('click',function(){
            profileBanner.classList.toggle("profile-favourite__banner_open");
        });
    }


}