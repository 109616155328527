export const productBtn = () => {

    const productBtnCart = document.querySelector('.js-product-cart');

    if ( productBtnCart ) {
        let top = productBtnCart.getBoundingClientRect().y
        
        window.addEventListener('scroll',function(){
            fix_button();
        });

        fix_button()

        function fix_button() {
            if ( window.innerWidth < 480 ) {
                if ( +top > window.innerHeight + window.pageYOffset - 90 ) {
                    productBtnCart.classList.add("block-product__cart_fix");
                } else {
                    productBtnCart.classList.remove("block-product__cart_fix");
                }
            }
        }
    }

}