export const modals = () => {

    const modalOpen = 'js-modal-open';
    const modalClose = document.querySelectorAll('.js-modal-close');
    const modals = document.querySelectorAll('.modal');
    const forms = document.querySelectorAll('.js-form');
    var distance = 0;


    if ( document.querySelector('.js-new-home-type') ) {
        document.querySelector('.js-new-home-type').addEventListener('change',function(){
            if ( document.querySelector('.js-new-home-type').checked ) {
                document.querySelector('.js-new-home')?.removeAttribute("required");
            } else {
                document.querySelector('.js-new-home')?.setAttribute("required", true);
            }
        });
    }


    // удалить на релизной версии
    document.querySelector('.js-clear-cookie')?.addEventListener("click", () => {
        localStorage.setItem("cookies_accept", "");
    });
    // удалить на релизной версии

    // удалить на релизной версии
    document.querySelector('.js-clear-city')?.addEventListener("click", () => {
        localStorage.setItem("start_city", "");
    });
    // удалить на релизной версии


    if ( localStorage.getItem("cookies_accept") != "true" ) {
        document.querySelector('.modal[data-modal="cookies"]')?.classList.add("modal_open");
    }

    function accept_cookies() {
        if ( document.querySelector('.modal_open').getAttribute("data-modal") == 'cookies' ) {
            localStorage.setItem("cookies_accept", "true");
        }
    }

    if ( localStorage.getItem("start_city") != "true" ) {
        document.querySelector('.modal[data-modal="askCity"]')?.classList.add("modal_open");
    }

    function accept_city() {
        if ( document.querySelector('.modal_open').getAttribute("data-modal") == 'askCity' ) {
            localStorage.setItem("start_city", "true");
        }
    }




    function disable_scroll() {
        document.querySelector(".site-container").classList.add("dis-scroll");
        document.querySelector(".site-container").scrollTo({
          top: distance,
          left: 0,
        });
    }

    function enable_scroll() {

        document.querySelector(".site-container").classList.remove("dis-scroll");

        if ( distance != 0 ) {
            setTimeout(() => {
                window.scrollTo({
                    top: distance,
                    left: 0,
                });
            }, 1);
        }

    }


    document.addEventListener('click',function(e){
        if ( e.target.classList.contains(modalOpen) ) {

            let modalName = e.target.dataset.modal;

            if (
                modalName == 'sizes' ||
                modalName == 'filters'
            ) {
                if ( window.innerWidth <= 744 ) {
                    distance = window.pageYOffset;
                    disable_scroll();
                    document.querySelector(`.modal[data-modal=${modalName}]`)?.classList.add("modal_open");
                }
            } else {
                distance = window.pageYOffset;
                disable_scroll();
                document.querySelector('.modal_open')?.classList.remove('modal_open');
                document.querySelector(`.modal[data-modal=${modalName}]`)?.classList.add("modal_open");
            }
            
        }
    });





    document.addEventListener('keydown', function(e) {
        let keyCode = e.keyCode;
        if (keyCode === 27) {
            accept_cookies();
            accept_city();
            document.querySelector('.modal_open')?.classList.remove('modal_open');
            enable_scroll();
        }
    });

    if ( modals.length ) {
        for (let modal of modals ) {
            modal.addEventListener('mousedown',function(e){
                if ( e.target.classList.contains("modal") ) {
                    accept_cookies();
                    accept_city();
                    document.querySelector('.modal_open')?.classList.remove('modal_open');
                    enable_scroll();
                }
            });
        }
    }

    if ( modals.length ) {
        for (let close of modalClose ) {
            close.addEventListener('mousedown',function(e){
                accept_cookies();
                accept_city();
                document.querySelector('.modal_open')?.classList.remove('modal_open');
                enable_scroll();
            });
        }
    }


    if ( forms.length ) {
        for ( let form of forms) {
        form.addEventListener('submit', e => {
            e.preventDefault();
            let formData = new FormData(form);
            let formAction = form.getAttribute("action");
            let formSuccess = form.dataset.success;
            //let formLink = form.dataset.link;
            fetch(formAction, {
                method: "POST",
                body: formData
            }).then(response => response.json()).then(response => {
                if (response.success) {
                    form.reset();
                    console.log('form send success');
                    if ( formSuccess ) {
                        document.querySelector('.modal_open')?.classList.remove('modal_open');
                        document.querySelector(`.modal[data-modal=${formSuccess}]`)?.classList.add("modal_open");
                    } else {
                        document.querySelector('.modal_open')?.classList.remove('modal_open');
                    }
                    /* if ( formLink ) {
                        window.location = formLink;
                    } */
                } else {
                    console.log('form send error');
                    alert('form send error');
                }
            });
        })
        }
    }




    // регистрация / авторизация

    


}