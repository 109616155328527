

export const scrollTo = () => {

    
    const scrollTo = document.querySelectorAll('.js-scroll-to');


    if ( scrollTo.length ) {
        for (let scroll of scrollTo ) {
            scroll.addEventListener('click',function(e){
                e.preventDefault();
                
                let elementId = this.dataset.scroll;

                //let elementGap = archon.querySelector(".js-alphabet-archon-mark")?.offsetTop - (document.querySelector(".header")?.offsetHeight * x);
                //let elementPosition = archon.offsetTop + elementGap;

                //document.querySelector(".alphabet__link_active")?.classList.remove("alphabet__link_active");
                //btn.classList.add("alphabet__link_active");

                let elementGap = 150;

                if ( document.getElementById(elementId) ) {
                    let elementPosition = document.getElementById(elementId).offsetTop - elementGap;

                    window.scrollTo({
                        top: elementPosition,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
                

            });
        }
    }


}