export const sizeCalc = () => {

    const sizeCalcBoxes = document.querySelectorAll('.js-size-calc');

    for (let box of sizeCalcBoxes ) {
        
        if ( box.dataset.calc == 'breast' ){

            let input1 = box.querySelector("input[name=sizes-breast-1]");
            let input2 = box.querySelector("input[name=sizes-breast-2]");
            let result = box.querySelector(".js-size-calc-result");
            let equal = box.querySelector(".js-size-calc-equal");

            input1.onchange = () => {
                if ( input1.value != '' && input2.value != '' ) {
                    if ( +input1.value > +input2.value ) {
                        calc_breast();
                    }
                }
            }

            input2.onchange = () => {
                if ( input1.value != '' && input2.value != '' ) {
                    if ( +input1.value > +input2.value ) {
                        calc_breast();
                    }
                }
            }




            function calc_breast() {
                
                let sizeDiff = +input1.value - +input2.value;
                let sizeNumber = Math.round( input2.value / 5 ) * 5;
                let sizeLetter;

                if ( sizeDiff <= 12 ) { sizeLetter = 'AA' }
                if ( sizeDiff > 12 ) { sizeLetter = 'A' }
                if ( sizeDiff > 14 ) { sizeLetter = 'B' }
                if ( sizeDiff > 16 ) { sizeLetter = 'C' }
                if ( sizeDiff > 18 ) { sizeLetter = 'D' }
                if ( sizeDiff > 20 ) { sizeLetter = 'E' }
                if ( sizeDiff > 22 ) { sizeLetter = 'F' }
                
                console.log('', 
                    sizeNumber + sizeLetter
                );

            }
            


        }

    }

}