export const siteSearch = () => {

    const siteSearch = document.querySelector('.js-site-search');
    const siteSearchInput = document.querySelector('.js-site-search input');

    if ( siteSearch ) {
        
        siteSearch.addEventListener('submit',function(e){
            e.preventDefault();
            let formData = new FormData(siteSearch);
            console.log('', formData);
            let formAction = siteSearch.getAttribute("action");
            fetch(formAction, {
                method: "POST",
                body: formData
            }).then(response => response.json()).then(response => {
                if (response.success) {

                    //alert( 'correct' )

                    siteSearch.querySelector(".input-block")?.classList.remove("error-field");

                    if ( document.querySelector(".modal__search-number") ) {
                        document.querySelector(".modal__search-number").innerHTML = response.number;
                    }
                    if ( document.querySelector(".modal__search-ask") ) {
                        document.querySelector(".modal__search-ask").innerHTML = response.ask;
                    }

                    document.querySelector('.modal__search-line')?.classList.remove("display-none");
                    document.querySelector('.modal__search-results')?.classList.remove("display-none");
                    document.querySelector('.modal__search-preview')?.classList.add("display-none");

                } else {
                    
                    //alert( 'wrong' )

                    siteSearch.querySelector(".input-block")?.classList.add("error-field");

                    document.querySelector('.modal__search-line')?.classList.add("display-none");
                    document.querySelector('.modal__search-results')?.classList.add("display-none");
                    document.querySelector('.modal__search-preview')?.classList.remove("display-none");

                }
            });
        });

    }

}