const drop = () => {
  // dragenter - объект над dropArea
  // dragleave - объект за пределами dropArea
  // dragover - объект зависает над dropArea
  // drop - объект отправлен в dropArea

  let filesSize = 0;
  let filesCount = 0;
  let filesArray = new DataTransfer();
  let filesError = new DataTransfer();
  const filesList = document.querySelector(".drop-file-list");
  const template = document.querySelector(".js-file-template");
  const templateError = document.querySelector(".js-file-template-error");
  const formBtn = document.querySelector(".js-drop-form-btn");
  const drag_drop = document.querySelector(".drag-drop");
  const drag_drop_input = drag_drop.querySelector(".drag-drop input");

  if (drag_drop) {
  }

  const fileInputs = document.querySelectorAll('[name="upload"]');
  // массив событий
  ["dragenter", "dragleave", "dragover", "drop"].forEach((eventName) => {
    fileInputs.forEach((input) => {
      input.addEventListener(eventName, preventDefaults, false);
    });
  });

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function highlight(item) {
    item.closest(".drag-drop").style.outline = "0.5rem solid yellow";
    // item.closest('.file_upload').style.backgroundColor = 'rgba(0,0,0,.2)';
  }

  function unhighlight(item) {
    item.closest(".drag-drop").style.outline = "none";
    // if (item.closest('.calc-form')) {
    //   item.closest('.file_upload').style.backgroundColor = '#fff';
    // } else {
    //   item.closest('.file_upload').style.backgroundColor = '#ededed';
    // }
  }

  ["dragenter", "dragover"].forEach((eventName) => {
    fileInputs.forEach((input) => {
      input.addEventListener(eventName, () => highlight(input), false);
    });
  });

  ["dragleave", "drop"].forEach((eventName) => {
    fileInputs.forEach((input) => {
      input.addEventListener(eventName, () => unhighlight(input), false);
    });
  });

  ["drop", "change"].forEach((eventName) => {
    fileInputs.forEach((input) => {
      input.addEventListener(eventName, (e) => addFile(e, input, eventName));
    });
  });

  function addFile(e, input, eventName) {
    if (eventName == "drop") {
      input.files = e.dataTransfer.files;
    }
    let files = input.files;

    for (let i = 0; i < files.length; i++) {
      // console.log(files[i]);
      addFileToFilesArray(files[i], i, eventName);
    }

    drag_drop_input.files = filesArray.files;

    function addFileToFilesArray(data, index, eventName) {
      const arr = data.name.split(".");

      if (checkCount()) {
        if (checkExistToList(data.name)) {
          if (checkFile(input, arr[1])) {
            if (checkSize(input, data.size)) {
              filesCount += 1;
              filesArray.items.add(data);
              drag_drop_input.files = filesArray.files;
              let file = new File(
                data,
                arr[0],
                arr[1],
                data.size / 1024 / 1024
              );
              file.setTemplate();
            } else {
              if (data.size / 1024 / 1024 > 150) {
                let file = new FileError(
                  data,
                  "Слишком большой файл",
                  arr[1],
                  data.size / 1024 / 1024
                );
                file.setTemplate();
              } else {
                let file = new FileError(
                  data,
                  "Закончилось свободное место",
                  arr[1],
                  data.size / 1024 / 1024
                );
                file.setTemplate();
              }
            }
          } else {
            let file = new FileError(
              data,
              "Неверный формат файла",
              arr[1],
              data.size / 1024 / 1024
            );
            file.setTemplate();
          }
        } else {
          let file = new FileError(
            data,
            "Такой файл уже добавлен",
            arr[1],
            data.size / 1024 / 1024
          );
          file.setTemplate();
        }
      } else {
        let file = new FileError(
          data,
          "Выберите до 10 файлов",
          arr[1],
          data.size / 1024 / 1024
        );
        file.setTemplate();
        // console.log("Не добавлять");
        // drag_drop.classList.add("disabled_drop");
      }

      // if (goodFile && goodSize) {
      // } else {
      //   console.warn(
      //     "Файл ",
      //     data,
      //     " c индексом ",
      //     index,
      //     " не подходит под требования"
      //   );
      // }
    }

    // const arr = input.files[0].name.split(".");

    // let goodFile = checkFile(input, arr[1]);
    // let goodSize = checkSize(input, input.files[0].size);

    // if (goodFile && goodSize) {
    //   initTemplate(
    //     input,
    //     arr[0],
    //     arr[1],
    //     Number(input.files[0].size) / 1024 / 1024
    //   );
    // } else {
    //   alert("Файл не подходит");
    // }
  }

  function checkCount() {
    return filesCount < 10 ? true : false;
  }

  function checkExistToList(name) {
    for (let i = 0; i < filesArray.files.length; i++) {
      if (filesArray.files[i].name == name) {
        return false;
      }
    }

    return true;
  }

  function checkFile(input, file) {
    let exts = input.accept.split(",");

    for (let i = 0; i < exts.length; i++) {
      if (exts[i].replace(".", "").trim().toUpperCase() == file.toUpperCase()) {
        return true;
      }
    }

    return false;
  }

  function checkSize(input, size) {
    let maxBytes = Number(input.dataset.size) * 1024 * 1024;

    if (size > maxBytes) {
      return false;
    }

    if (size > maxBytes - filesSize) {
      return false;
    }

    filesSize += size;
    return true;
  }

  class File {
    constructor(input, nameFile, extFile, maxFile) {
      this.data = input;
      this.name = nameFile;
      this.ext = extFile;
      this.size = maxFile;
      this.htlm = null;
      this.clearHtml = null;
      this.watchHtml = null;
      // api
      this.url = "https://jsonplaceholder.typicode.com/posts";
      this.videoLoad = false;
      this.videoId = null;
      this.videoData = null;
      this.finishLoadToForm = true;
      // anim
      this.nowAnim = null;
      this.countAnim = null;
      this.lineAnim = null;
    }
    setTemplate() {
      let elem = document.createElement("div");
      elem.classList.add("drop-file", "js-file");
      this.html = elem;
      elem.append(template.content.cloneNode(true));
      filesList.append(elem);

      const name = elem.querySelectorAll(".js-file-name span");
      const ext = elem.querySelector(".js-file-ext");
      const line = elem.querySelector(".js-file-line");
      const count = elem.querySelector(".js-file-count");
      const now = elem.querySelector(".js-file-now");
      const max = elem.querySelector(".js-file-max");
      const clear = elem.querySelector(".js-file-clear");
      const watch = elem.querySelector(".js-file-watch");

      this.clearHtml = clear;
      this.watchHtml = watch;
      this.nowAnim = now;
      this.countAnim = count;
      this.lineAnim = line;

      name[0].textContent = this.name;
      name[1].textContent = "." + this.ext.toLowerCase();

      ext.textContent = this.ext.toUpperCase();
      max.textContent = this.size.toFixed(1);

      now.textContent = 0;
      count.textContent = 0;
      line.style.cssText = `--width: ${0}%;`;

      this.uploadingToServer();
      this.calcStep();
      this.clearHtml.addEventListener("click", this.deleteFnc);
    }

    enable_fnc() {
      [this.clearHtml, this.watchHtml].forEach((item) => {
        item.classList.remove("disabled_drop");
        item.disabled = false;
      });
      this.html.classList.add("drop-file-ex");
    }

    disabled_fnc() {
      [this.clearHtml, this.watchHtml].forEach((item) => {
        item.classList.add("disabled_drop");
        item.disabled = true;
      });
    }

    async uploadingToServer() {
      this.disabled_fnc();
      const data = new FormData();
      // data.append("file", this.data);
      data.append("file", "binary");

      let resp = await fetch(this.url, {
        method: "POST",
        // mode: "no-cors",
        body: data
      })
        .then((response) => response.json())
        .then((result) => this.loadVideo(result))
        .catch((error) => console.error(error));

      return resp;
    }

    deleteFnc = async () => {
      let filesArrayNew = new DataTransfer();

      for (let i = 0; i < filesArray.files.length; i++) {
        if (filesArray.files[i].name != this.data.name) {
          filesArrayNew.items.add(filesArray.files[i]);
        }
      }

      filesArray = filesArrayNew;
      drag_drop_input.files = filesArray.files;

      const data = `/${this.videoId}`;

      let resp = await fetch(this.url + data, {
        method: "DELETE",
        // mode: "no-cors",
        body: data
      })
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => console.error(error));

      this.videoData = null;
      this.videoLoad = true;
      this.videoId = null;

      filesCount--;
      filesSize -= this.size;

      this.html.remove();
    };

    deleteFile() {}

    loadVideo = (data) => {
      console.log(data);
      this.videoData = data;
      this.videoLoad = true;
      // this.watchHtml.href = data.data.file_url;
      this.videoId = data.data.file_id;
    };

    calcStep() {
      let step = this.size.toFixed(1) / 100;
      let percent = 0;
      let whatNow = 0;
      let paused = false;
      let stepSec = null;
      let maxLocal = this.size.toFixed(1);

      if (maxLocal <= 20) {
        stepSec = 200;
      } else if (maxLocal <= 50) {
        stepSec = 300;
      } else if (maxLocal <= 100 && maxLocal > 50) {
        stepSec = 400;
      } else if (maxLocal > 100) {
        stepSec = 600;
      }

      const setItems = () => {
        this.nowAnim.textContent = whatNow.toFixed(1);
        this.countAnim.textContent = percent;
        this.lineAnim.style.cssText = `--width: ${percent}%;`;
      };

      const takeStep = () => {
        if (true) {
          if (percent < 100) {
            if (percent == 99 && !this.videoLoad) {
            } else {
              if (this.videoLoad) {
                whatNow += step;
                percent++;
                setItems();

                if (percent < 100) {
                  setTimeout(takeStep, 3);
                }
              } else {
                whatNow += step;
                percent++;
                setItems();
              }
            }
          } else {
            this.enable_fnc();
            clearInterval(intervalStep);
          }
        }
      };

      let intervalStep = setInterval(takeStep, stepSec);
    }
  }

  class FileError {
    constructor(input, errorName, extFile, maxFile) {
      this.data = input;
      this.error = errorName;
      this.ext = extFile;
      this.size = maxFile;
      this.htlm = null;
      this.clearHtml = null;
      this.anotherHtml = null;
    }

    setTemplate() {
      let elem = document.createElement("div");
      elem.classList.add("drop-file", "js-file", "drop-file-error");
      this.html = elem;
      elem.append(templateError.content.cloneNode(true));
      filesList.append(elem);

      const name = elem.querySelector(".js-file-name");
      const ext = elem.querySelector(".js-file-ext");
      const max = elem.querySelector(".js-file-max");
      const clear = elem.querySelector(".js-file-clear");
      const another = elem.querySelector(".js-file-another");
      const now = elem.querySelector(".js-file-now");

      this.clearHtml = clear;
      this.anotherHtml = another;

      name.textContent = this.error;

      ext.textContent = this.ext.toUpperCase();
      max.textContent = this.size.toFixed(1);
      now.textContent = this.size.toFixed(1);

      this.clearHtml.addEventListener("click", this.deleteFnc);
      this.anotherHtml.addEventListener("click", this.clickToInput);
    }
    deleteFnc = () => {
      this.videoData = null;
      this.videoLoad = true;
      this.videoId = null;

      this.html.remove();
    };
    clickToInput() {
      drag_drop_input.click();
    }
  }
};

export default drop;
