export const haeder = () => {

    const dropMenuLinks = document.querySelectorAll('.js-drop-menu-link');
    const dropMenuAddictions = document.querySelectorAll('.js-drop-menu-addiction');
    const dropMenu = document.querySelector('.js-drop-menu');
    const bannerClose = document.querySelector('.js-header-banner-close');

    if ( dropMenuLinks.length && dropMenuAddictions.length ) {
        for (let link of dropMenuLinks ) {

            link.addEventListener('mouseover',function(){
                document.querySelector('.header__drop-link_hover')?.classList.remove("header__drop-link_hover");
                link.classList.add("header__drop-link_hover");
            });

            link.addEventListener('mouseover',function(){
                let addiction = link.dataset.addiction;

                for (let el of dropMenuAddictions ) {
                    if ( el.dataset.addiction == addiction ) {
                        el.classList.remove("header__drop-link_hidden");
                    } else {
                        el.classList.add("header__drop-link_hidden");
                    }
                }

                //console.log('', addiction);
            });
        }
    }

    if ( dropMenu && dropMenuAddictions.length ) {
        dropMenu.addEventListener('mouseleave',function(){
            document.querySelector('.header__drop-link_hover')?.classList.remove("header__drop-link_hover");
            for (let el of dropMenuAddictions ) {
                el.classList.add("header__drop-link_hidden");
            }
        });
    }

    if ( bannerClose ) {
        bannerClose.addEventListener('click',function(){
            bannerClose.closest(".header__banner").classList.add("header__banner_hidden");
        });
    }

}