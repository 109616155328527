export const modalMap = () => {

    const modalShopsWrap = document.querySelectorAll('.js-modal-shops');

    if ( modalShopsWrap.length ) {
        
        for (let wrap of modalShopsWrap ) {
            
            let shopsSeacrh = wrap.querySelector(".search__input");
            let shopsBlocks = wrap.querySelectorAll(".modal__list-item");
            
            if ( shopsSeacrh && shopsBlocks.length ) {
                shopsSeacrh.oninput = () => {

                    for (let block of shopsBlocks ) {
                        if ( block.dataset.search.toLowerCase().indexOf( shopsSeacrh.value.toLowerCase() ) != -1 ) {
                            block.classList.remove("display-none");
                        } else {
                            block.classList.add("display-none");
                        }
                    }
    
                }
            }
            
        }

    }

    

}