export const certificate = () => {

    const certificateTarget = document.querySelector('.js-certificate-target');
    const takerBlocks = document.querySelectorAll('.certificate__block_taker');
    const certificateNomianal = document.querySelector('.js-certificate-nominal');
    const certificateNominalInput = document.querySelector('.js-certificate-nominal-input');


    if ( certificateTarget ) {
        let radios = certificateTarget.querySelectorAll("input");

        function blockStatus(status){
            if ( takerBlocks.length ) {
                takerBlocks.forEach( (e) => {
                    e.style.display = status;
                    e.querySelectorAll("input").forEach( (e) => {
                        if ( e.type != 'tel' ) {
                            if ( status == 'none' ) {
                                e.removeAttribute("required"); 
                            } else {
                                e.setAttribute("required", 'true'); 
                            }
                        }
                    } )
                } )
            }
        }

        for (let radio of radios ) {
            radio.addEventListener('click',function(){
                if ( radio.value == 'себе' ) {
                    blockStatus('none');
                } else {
                    blockStatus('flex');
                }
            });
        }

    }

    if ( certificateNomianal && certificateNominalInput ) {
        let radios = certificateNomianal.querySelectorAll("input");
        let input = certificateNominalInput;

        for (let radio of radios ) {
            radio.addEventListener('click',function(){
                let value = radio.value;
                certificateNominalInput.value = value;
            });
        }

        input.oninput = () => {
            
            input.value = input.value.replace("-", "");

            for (let radio of radios ) {
                radio.checked = false;
                if ( input.value == radio.value ) {
                    radio.checked = true;
                }
            }
        }

        input.onchange = () => {
            input.value = Math.ceil( input.value / 100 ) * 100;
            if ( +input.value > +input.max ) {
              input.value = input.max;
            }
            if ( +input.value == 0 ) {
              input.value = input.min;
            }
        }

    }


}