import Inputmask from "inputmask";

(function () {
  try {
    const tels = document.querySelectorAll('input[type="tel"]');
    const certificate = document.querySelectorAll("[data-sertificate-check]");

    const inputMask = new Inputmask("+7 (999) 999-99-99");
    tels.forEach((tel) => {
      inputMask.mask(tel);
    });

    const certificateMask = new Inputmask("9999-9999-9999-9999");
    certificate.forEach((text) => {
      certificateMask.mask(text);
    });
  } catch (e) {}
})();
