console.log("components");

import { swipers } from "../js/components/swipers";
swipers();

import { productCard } from "../js/components/productCard";
productCard();

import { autoRadio } from "../js/components/autoRadio";
autoRadio();

import { accordion } from "../js/components/accordion";
accordion();

import { haeder } from "../js/components/haeder";
haeder();

import { mobileMenu } from "../js/components/mobileMenu";
mobileMenu();

import { selects } from "../js/components/selects";
selects();

import { filters } from "../js/components/filters";
filters();

import { modals } from "../js/components/modals";
modals();

import { sliders } from "../js/components/sliders";
sliders();

import { productBtn } from "../js/components/productBtn";
productBtn();

import { tabs } from "../js/components/tabs";
tabs();

import { yandexMap } from "../js/components/yandexMap";
yandexMap();

import { certificate } from "../js/components/certificate";
certificate();

import { scrollTo } from "../js/components/scrollTo";
scrollTo();

import { cart } from "./components/cart";
cart();

import { modalMap } from "./components/modalMap";
modalMap();

import { sizeCalc } from "./components/sizeCalc";
sizeCalc();

import { message } from "./components/message";
message();

import { certificateCheck } from "./components/certificateCheck";
certificateCheck();

import { siteSearch } from "./components/siteSearch";
siteSearch();

import { login } from "./components/login";
login();

import { profile } from "./components/profile";
profile();


import drop from "./components/drop";
try {
  drop();
} catch (e) {}

import { valid } from "./components/valid";
import { phoneMask } from "./components/phoneMask";
import { validForm } from "./components/validForm";
import { calendarValue } from "./components/calendarValue";
import clearInput from "./components/clearInput";
try {
  clearInput();
} catch (e) {
  console.error(e);
}

import changeInput from "./components/changeInput";
try {
  changeInput();
} catch (e) {
  //console.error(e);
}
