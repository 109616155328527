export const sizeCalc = () => {

    const sizeCalcBoxes = document.querySelectorAll('.js-size-calc');

    for (let box of sizeCalcBoxes ) {

        box.querySelector("form")?.addEventListener("submit", (e) => {
            console.log('1', );
            e.preventDefault();
        });

        if ( box.dataset.calc == 'hips' ) {

            let input1 = box.querySelector("input[name=sizes-hips-1]");
            let result = box.querySelector(".js-size-calc-result");

            let tableLabel = box.querySelectorAll(".js-size-hips-label");
            let tableEqual = box.querySelector(".js-size-hips-equal");

            input1.onchange = (e) => {

                if ( input1.value != '' ) {

                    function calc_hips(x) {

                        let size = '';

                        if ( x > 83 )   { size = "0"; }
                        if ( x > 87 )   { size = "1"; }
                        if ( x > 91 )   { size = "2"; }
                        if ( x > 95 )   { size = "3"; }
                        if ( x > 99 )   { size = "4"; }
                        if ( x > 103 )  { size = "5"; }
                        if ( x > 107 )  { size = "6"; }
                        if ( x > 112 )  { size = "7"; }
                        if ( x > 116 )  { size = ""; }

                        if ( size != '' ) {
                            return size;
                        } else {
                            return 'Размер не найден'
                        }

                    }

                    result.innerHTML = calc_hips(input1.value);

                }
            }


            if ( tableLabel.length ) {
                for (let label of tableLabel ) {
                    label.addEventListener('click',function(){

                        tableEqual.innerHTML = label.dataset.size + " см";

                    });
                }
            }


        }
        
        if ( box.dataset.calc == 'breast' ){

            let input1 = box.querySelector("input[name=sizes-breast-1]");
            let input2 = box.querySelector("input[name=sizes-breast-2]");
            let result = box.querySelector(".js-size-calc-result");
            let equal = box.querySelector(".js-size-calc-equal");
            
            let tableLabel = box.querySelectorAll(".js-size-breast-label");
            let tableBreast1 = box.querySelector(".js-size-breast-1");
            let tableBreast2 = box.querySelector(".js-size-breast-2");
            let tableEqual = box.querySelector(".js-size-breast-equal");

            input1.onchange = () => {
                if ( input1.value != '' && input2.value != '' ) {
                    result.innerHTML = calc_breast(0,0);

                    if ( calc_breast(-5,2) != '' && calc_breast(5,-2) != '' ) {
                        equal.innerHTML = calc_breast(-5,2) + ", " + calc_breast(5,-2);
                    } else {
                        equal.innerHTML = calc_breast(-5,2) + calc_breast(5,-2);
                    }

                }
            }

            input2.onchange = () => {
                if ( input1.value != '' && input2.value != '' ) {
                    result.innerHTML = calc_breast(0,0);
                    
                    if ( calc_breast(-5,2) != '' && calc_breast(5,-2) != '' ) {
                        equal.innerHTML = calc_breast(-5,2) + ", " + calc_breast(5,-2);
                    } else {
                        equal.innerHTML = calc_breast(-5,2) + calc_breast(5,-2);
                    }

                }
            }

            function calc_breast(x,y) {
                let sizeDiff = +input1.value - +input2.value ;
                let sizeLetter = '';

                if ( +input1.value > +input2.value ) {
                    
                    let sizeNumber = Math.round( (+input2.value + +x) / 5 ) * 5;

                    //console.log('sizeNumber', sizeNumber);

                    if ( (+sizeDiff + +y) > 10 ) { sizeLetter = 'AA' }
                    if ( (+sizeDiff + +y) > 12 )  { sizeLetter = 'A' }
                    if ( (+sizeDiff + +y) > 14 )  { sizeLetter = 'B' }
                    if ( (+sizeDiff + +y) > 16 )  { sizeLetter = 'C' }
                    if ( (+sizeDiff + +y) > 18 )  { sizeLetter = 'D' }
                    if ( (+sizeDiff + +y) > 20 )  { sizeLetter = 'E' }
                    if ( (+sizeDiff + +y) > 22 )  { sizeLetter = 'F' }
                    if ( (+sizeDiff + +y) > 24 )  { sizeLetter = '' }

                    //console.log('sizeLetter', sizeLetter);

                    if ( +sizeDiff < 11 || +sizeDiff > 24 ) {
                        if ( x != 0 || y != 0 ) {
                            return '';
                        } else {
                            return 'Размер не найден';
                        }
                    } else if ( sizeLetter == '' ) {
                        if ( x != 0 || y != 0 ) {
                            return '';
                        } else {
                            return 'Размер не найден';
                        }
                    } else {

                        return sizeNumber + sizeLetter;

                    }


                } else {
                    if ( x != 0 || y != 0 ) {
                        return '';
                    } else {
                        return 'Размер не найден';
                    }
                }

            }

            if ( tableLabel.length ) {
                for (let label of tableLabel ) {
                    label.addEventListener('click',function(){

                        let letters = ["AA","A","B","C","D","E","F"];
                        let i = letters.indexOf(label.dataset.letter);

                        function get_letter(x) {
                            if ( x == 'next' ) {
                                return letters[i+1];
                            }
                            if ( x == 'prev' ) {
                                if ( i == 0 ) {
                                    i = 1;
                                }
                                return letters[i-1];
                            }
                        }

                        function get_difference() {
                            let gaps = ["11-12", "13-14", "15-16", "17-18", "19-20", "21-22", "23-24"];
                            let diff = gaps[i].split("-");

                            return (+label.dataset.size - 2 + +diff[0]) + "-" + (+label.dataset.size + 2 + +diff[1]) + " см";

                        }
                        
                        if( tableBreast1 ) {
                            tableBreast1.innerHTML =  get_difference();
                        }

                        if( tableBreast2 ) {
                            tableBreast2.innerHTML = (+label.dataset.size - 2) + "-" + (+label.dataset.size + 2) + " см";
                        }
                        
                        if( tableEqual ) {
                            tableEqual.innerHTML = (+label.dataset.size - 5) + get_letter("next") + ", " + (+label.dataset.size + 5) + get_letter("prev");
                        }

                    });
                }
            }


        }

    }

}